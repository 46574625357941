import React, { useEffect, useState } from 'react';
import '../../css/billstyle.css'
import {useDispatch, useSelector } from 'react-redux';
import { fatchUserAPI,fatchPhotoAPI,fatchSignAPI,fatchQuotationIDAPI,fatchTearmsAPI,fatchBankAPI} from '../../redux/actionCreation';import { parseISO, format } from 'date-fns';
import 'react-datepicker/dist/react-datepicker.css';
import { Link, useNavigate, useParams } from 'react-router-dom';
import 'react-quill/dist/quill.snow.css'; // Import the styles
import DOMPurify from 'dompurify';
import { Helmet } from 'react-helmet';
import html2pdf from 'html2pdf.js';
import Loader from '../Loader';
import { Row } from 'react-bootstrap';

function QuoteEditdesign() {
    //view port set
    useEffect(() => {
        // Update the viewport meta tag when ABCComponent mounts
        const metaTag = document.querySelector('meta[name="viewport"]');
        if (metaTag) {
          metaTag.setAttribute('content', 'width=device-width, initial-scale=0.4, maximum-scale=1.0, user-scalable=yes');
        }
    
        // Clean up function to reset the viewport when ABCComponent unmounts
        return () => {
          if (metaTag) {
            metaTag.setAttribute('content', 'width=device-width, initial-scale=1');
          }
        };
      }, []); // Run only once on component mount
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const dispatchFetchPhoto = useDispatch();
    const fetchQuotIdReducers = useSelector(state => state.fetchQuotIdReducers);
    const QuoteData = fetchQuotIdReducers.data;
    const fetchQutReducers = useSelector(state => state.fetchQutReducers);
    const FetchBankReducers = useSelector(state => state.FetchBankReducers);
    const FeatchTearmsReducers = useSelector(state => state.FeatchTearmsReducers);
    const signatures = useSelector((state) => state.fetchReducers);
    const hasSignature = signatures.data.some(signature => signature.signature_data);
    const fetchcompanyLogo = useSelector(state => state.fetchcompanyLogo);
    const fetchUserReducres = useSelector(state => state.fetchUserReducres);
    const email = fetchUserReducres.data ? fetchUserReducres.data.email : null;
    const company_name = fetchUserReducres.data ? fetchUserReducres.data.company_name : null;
    const phone_number = fetchUserReducres.data ? fetchUserReducres.data.phone_number : null;
    const phone_number_2 = fetchUserReducres.data ? fetchUserReducres.data.phone_number_2 : null;
    const company_website = fetchUserReducres.data ? fetchUserReducres.data.company_website : null;
    const company_GST = fetchUserReducres.data ? fetchUserReducres.data.company_GST : null;
    const company_add = fetchUserReducres.data ? fetchUserReducres.data.company_add : null;
    const navigate = useNavigate();
    useEffect(()=>{
        if(!localStorage.getItem('tokan')){
            navigate('/login');
        }
    }, []);

    useEffect(() => {
        const fetchDataUser = async () => {
            try {
                const res = await dispatch(fatchUserAPI());
            } catch (error) {
                console.error('Error fetching Company Details data:', error);
            }
        };
        fetchDataUser();
    }, []);

    const { id } = useParams();
    useEffect(() => {
        const fetchDataQute = async () => {
        try {
            const res = await dispatch(fatchQuotationIDAPI(id));
            console.log('API Response:', res);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
        };
        fetchDataQute(); // Call the async function
    }, [id]); // Include 'id' in the dependency array

    useEffect(() => {
        const fetchDataSign = async () => {
            try {
                const res = await dispatch(fatchSignAPI());
                // Process the result if needed
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchDataSign(); // Call the async function
    }, []);

    useEffect(() => {
        const fetchPhoto = async () => {
            try {
              const res = await dispatchFetchPhoto(fatchPhotoAPI());
                // Process the result if needed
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchPhoto(); // Call the async function
    }, []);

    useEffect(() => {
        const fetchDataTearms = async () => {
            try {
                const res = await dispatch(fatchTearmsAPI());
                // Process the result if needed
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchDataTearms(); // Call the async function
    }, []);

    useEffect(() => {
        const fetchBank = async () => {
            try {
                const res = await dispatch(fatchBankAPI());
                // Process the result if needed
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchBank(); // Call the async function
    }, []);
    
    const imageUrl = `https://app.packersbilling.in/api/${fetchcompanyLogo.data?.[0]?.path}`;
    const [base64URL, setBase64URL] = useState('');
    useEffect(() => {
        const fetchImage2 = async () => {
        try {
            const response = await fetch(imageUrl);
            if (!response.ok) {
            throw new Error(`Failed to fetch image. Status: ${response.status}`);
            }
            const blob = await response.blob();
            const reader = new FileReader();
            reader.onloadend = () => {
            const base64 = reader.result;
            setBase64URL(base64);
            };
            reader.readAsDataURL(blob);
        } catch (error) {
            console.error('Error fetching or converting the image:', error);
        }
        };
  
        fetchImage2();
    }, [imageUrl]);
  
    //   const handleDownloadPdf = () => {
    //     const element = document.getElementById('content-to-pdf');
    //     html2pdf(element, {
    //       margin: 19,
    //       filename: 'quotation.pdf',
    //       image: { type: 'jpeg', quality: 0.98 },
    //       html2canvas: { scale: 2 },
    //       jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
    //     });
    //   };

      const handleDownloadPdf = () => {
        const element = document.getElementById('content-to-pdf');
        html2pdf(element, {
            margin: [0, 5, 25, 5], // [top, left, bottom, right]
            filename: 'quotation.pdf',
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 2, useCORS: true },
            jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
        });
    };
    

  return (
    <>
    {loading && <div className='text-center mt-4'>
        <Loader />
    </div>}
    {!loading && (
        <Row>
            {QuoteData && typeof QuoteData === 'object' && (
            <div className="container-fluid invoice-container"> 
                <div id='content-to-pdf'>
                    <header>
                        <div className="row align-items-center gy-3">
                            <div className="col-sm-3 text-center text-sm-start">
                                {base64URL ? (
                                        <div>
                                        <img src={base64URL} alt="logo" className='logo'/>
                                        </div>
                                    ) : (
                                        <Link to="/update-company" className='btn btn-primary'>Add Logo</Link>
                                    )}
                            </div>
                            <div className="col-sm-9 text-center text-sm-end">
                                <h4 className="mb-1">{company_name}</h4>
                                <p className="mb-1"><i className='fa fa-map-marker-alt'></i> {company_add}</p>
                                <div className='d-flex align-items-center justify-content-end mb-1'>
                                    <p className="mb-0 mx-2 email"><i className='fa fa-envelope'></i> {email}</p>
                                    <p className="mb-0"><i className='fa fa-phone'></i> +91 {phone_number} {phone_number_2 && ( // Check if phone_number_2 exists
                                        <span>, {phone_number_2}</span>
                                    )}</p>
                                </div>
                                <div className='d-flex align-items-center justify-content-end'>
                                    {company_GST && ( // Check if company_GST exists
                                        <p className="mb-0 mx-2"><i className='fa fa-file'></i> GSTIN : {company_GST}</p>
                                    )}
                                    {company_website && ( // Check if company_GST exists
                                        <p className="mb-0 text-lowercase"><i className='fa fa-globe-americas'></i> {company_website}</p>
                                    )}
                                </div>
                            </div>
                        </div>
                        <hr/>
                    </header>
                    <main>
                        <div className="row gy-3 bg-red">
                        <div className="col mt-2 mb-2"> <strong>Quotation Number:</strong> <span>{QuoteData.quotation_number}</span> </div>
                        <div className="col text-sm-center mt-2 mb-2"> <strong>Service :</strong> <span>{QuoteData.service_name}</span> </div>
                        <div className="col text-sm-end mt-2 mb-2"> <strong>Quotation Date:</strong> <span>{(() => {
                                    try {
                                    return format(parseISO(QuoteData.quotation_date), 'dd-MM-yyyy');
                                    } catch (error) {
                                    console.error('Error parsing date:', QuoteData.quotation_date);
                                    return 'Invalid Date';
                                    }
                                })()}</span> </div>
                        </div>
                        <hr className='mb-2 mt-0'/>
                        <div className="row">
                            <div className="col-sm-5">
                                <strong className='mb-1 d-block'>Customer Name :</strong>
                                <p>{QuoteData.name}</p>
                            </div>
                            <div className="col-sm-3 text-end">
                                <strong className='mb-1 d-block'>Customer Mo :</strong>
                                <p>{QuoteData.phone_number}</p>
                            </div>

                            <div className="col-sm-2 text-end">
                                <strong className='mb-1 d-block'>Start Date :</strong>
                                <p>{(() => {
                                    try {
                                    return format(parseISO(QuoteData.shift_start_date), 'dd-MM-yyyy');
                                    } catch (error) {
                                    console.error('Error parsing date:', QuoteData.shift_start_date);
                                    return 'Invalid Date';
                                    }
                                })()}</p>
                            </div>

                            <div className="col-sm-2 text-end">
                                <strong className='mb-1 d-block'>End Date :</strong>
                                <p>{(() => {
                                    try {
                                    return format(parseISO(QuoteData.shift_end_date), 'dd-MM-yyyy');
                                    } catch (error) {
                                    console.error('Error parsing date:', QuoteData.shift_end_date);
                                    return 'Invalid Date';
                                    }
                                })()}</p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-8">
                                <strong className='mb-1 d-block'>Customer Address :</strong>
                                <p>{QuoteData.customer_address}</p>
                            </div>
                            <div className="col-sm-2">
                                <strong className='mb-1 d-block'>From :</strong>
                                <p>{QuoteData.from_city}</p>
                            </div>

                            <div className="col-sm-2">
                                <strong className='mb-1 d-block'>To :</strong>
                                <p>{QuoteData.to_city}</p>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-sm-12">
                                <strong className='mb-1 d-block'>Shifting Items :</strong>
                                <p>{QuoteData.shift_items}</p>
                            </div>
                        </div>
                        
                        <div className="table-responsive">
                        <table className="table table-bordered text-1 mb-3">
                            <tbody>
                                <tr className="bg-light">
                                    <td colspan="6" className='pt-2 pb-2 pr-1 pl-2 text-center'><strong>Shifting Charges</strong></td>
                                </tr>
                                <tr>
                                    <td><strong>Transportation Charge</strong></td>
                                    <td>{QuoteData.transportation_charge}</td>
                                </tr>
                                <tr>
                                    <td><strong>Packing Charge</strong></td>
                                    <td >{QuoteData.packing_charge}</td>
                                </tr>
                                <tr>
                                    <td><strong>Unpacking Charge</strong></td>
                                    <td >{QuoteData.unpacking_charge}</td>
                                </tr>
                                <tr>
                                    <td><strong>Loading Charge</strong></td>
                                    <td >{QuoteData.loading_charge}</td>
                                </tr>
                                <tr>
                                    <td><strong>Unloading Charge</strong></td>
                                    <td >{QuoteData.unloading_charge}</td>
                                </tr>
                                <tr>
                                    <td><strong>Insurance Charge</strong></td>
                                    <td >{QuoteData.insurance_charge}</td>
                                </tr>
                                <tr>
                                    <td><strong>Service Charge</strong></td>
                                    <td >{QuoteData.service_charge}</td>
                                </tr>
                                <tr>
                                    <td><strong>Storage Charge</strong></td>
                                    <td >{QuoteData.storage_charge}</td>
                                </tr>
                                <tr>
                                    <td><strong>Other Charge</strong></td>
                                    <td >{QuoteData.other_charge}</td>
                                </tr>
                                <tr>
                                    <td><strong>SGST Charge({QuoteData.sgst_per}%)</strong></td>
                                    <td ><i className='fa fa-indian-rupee'></i> {QuoteData.sgst_charge}</td>
                                </tr>
                                <tr>
                                    <td><strong>CGST Charge({QuoteData.cgst_per}%)</strong></td>
                                    <td ><i className='fa fa-indian-rupee'></i> {QuoteData.cgst_charge}</td>
                                </tr>
                                <tr>
                                    <td><strong>IGST Charge ({QuoteData.gst_per}%)</strong></td>
                                    <td ><i className='fa fa-indian-rupee'></i> {QuoteData.gst_charge}</td>
                                </tr>

                                <tr>
                                    <td className='total_amount'><strong>Total</strong></td>
                                    <td className='total_amount'><strong><i className='fa fa-indian-rupee'></i> {QuoteData.total_amount}</strong></td>
                                </tr>
                            </tbody>
                        </table>
                        </div>
                        
                        <div className='d-flex align-items-center justify-content-between'>
                                    <div>
                                    <h4 className="text-3 mt-3 mb-1">Bank Details:</h4>
                                        {FetchBankReducers && Array.isArray(FetchBankReducers.data) && FetchBankReducers.data.length > 0 ? (
                                        [...FetchBankReducers.data].reverse().map(item => (
                                        <div key={item.id} class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-0">
                                            
                                            <div class="d-flex justify-content-between align-items-center mt-3">
                                                <p class="text-dark profile_name">Account Holder Name :</p> <h6 class="text-dark profile_name_data mx-2">{item.account_holder_name}</h6>
                                            </div>
                                            <div class="d-flex justify-content-between align-items-center">
                                                <p class="text-dark profile_name">Bank Name :</p>
                                                <h6 class="text-dark profile_name_data mx-2">{item.bank_name}</h6>
                                            </div>
                                            <div class="d-flex justify-content-between align-items-center">
                                                <p class="text-dark profile_name">Account Number:</p>
                                                <h6 class="text-dark profile_name_data mx-2">{item.account_number}</h6>
                                            </div>
                                            <div class="d-flex justify-content-between align-items-center">
                                                <p class="text-dark profile_name">IFFC Code :</p>
                                                <h6 class="text-dark profile_name_data mx-2">{item.iffc_code}</h6>
                                            </div>
                                        </div>
                                        ))
                                        ) : (
                                            <div className='text-left not_data mt-3 mb-3'>
                                                <Link to="/update-company" className='btn btn-primary'>Add Bank Details</Link>
                                            </div>
                                        )} 
                                    </div>
                                    <div className='text-end'>
                                        <h4 className="text-3 mt-4 stemp"><strong>For :</strong> {company_name}</h4>
                                        {[...signatures.data].map((signature, index) => (
                                            <img key={index} src={signature.signature_data} alt={`Signature ${index + 1}`} className='sign-size' />
                                        ))}
                                        {!hasSignature && (
                                            <Link to="/update-company" className='d-print-none btn btn-primary'>
                                                Add Sign
                                            </Link>
                                        )}
                                        <p className='mb-0 stemp'>Authorized Signature</p>
                                    </div>
                                </div>

                                <div className='d-flex align-items-center justify-content-between'>
                                    <div>
                                        <h4 className="text-3 mt-3">Terms and conditions:</h4>
                                        {FeatchTearmsReducers && Array.isArray(FeatchTearmsReducers.data) && FeatchTearmsReducers.data.length > 0 ? (
                                        [...FeatchTearmsReducers.data].reverse().map(item => (
                                            <h6 className="text-dark profile_name_data_terms printpage" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(item.tearms) }} />
                                            ))
                                            ) : (
                                                <div className='text-center not_data mt-3'>
                                                <Link to="/update-company" className='d-print-none btn btn-primary mb-3'>Add Tearms+</Link>
                                                </div>
                                            )} 
                                    </div>
                                </div>
                    </main>
                </div>
                <div className="text-center invoice_design">
                <div className="btn-group btn-group-sm d-print-none"> <a href="javascript:window.print()" className="btn btn-info quot_btn pt-2 pb-2"><i className="fa fa-print"></i> Print & Download</a>
            </div>

                <button
                    onClick={handleDownloadPdf}
                    type="button"
                    className='btn btn-info bill_btn pt-2 pb-2 d-print-none'>
                        Download as PDF
                </button>

            <Link class="btn btn-info plus_btn text-white pt-2 pb-2 d-print-none" to="/quotationList-list"><i className="fa fa-file"></i> All Quotation</Link>
                </div>
            </div>
            )}
        </Row>
    )}
 </>
)
}

export default QuoteEditdesign

