import React, { useEffect, useState } from 'react';
import '../../css/billstyle.css'
import { fatchUserAPI,fatchPhotoAPI,fatchSignAPI,fatchReciptIDAPI,fatchTearmsAPI,fatchBankAPI} from '../../redux/actionCreation';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { parseISO, format } from 'date-fns';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useRef } from 'react';
import 'react-quill/dist/quill.snow.css'; // Import the styles
import DOMPurify from 'dompurify';
import html2pdf from 'html2pdf.js';
import { Helmet } from 'react-helmet';
import Loader from '../Loader';
import { Row } from 'react-bootstrap';

function ReciptEditdesign() {
    //View port set 
    useEffect(() => {
        // Update the viewport meta tag when ABCComponent mounts
        const metaTag = document.querySelector('meta[name="viewport"]');
        if (metaTag) {
          metaTag.setAttribute('content', 'width=device-width, initial-scale=0.4, maximum-scale=1.0, user-scalable=yes');
        }
    
        // Clean up function to reset the viewport when ABCComponent unmounts
        return () => {
          if (metaTag) {
            metaTag.setAttribute('content', 'width=device-width, initial-scale=1');
          }
        };
      }, []); // Run only once on component mount
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const dispatchFetchPhoto = useDispatch();
    const navigate = useNavigate();
    useEffect(()=>{
        if(!localStorage.getItem('tokan')){
            navigate('/login');
        }
    }, []);
    
    const fetchcompanyLogo = useSelector(state => state.fetchcompanyLogo);
    if (fetchcompanyLogo.data.length > 0) {
        // Accessing the first object in the array
        console.log("fetch photo", fetchcompanyLogo.data[0].path);
    } else {
        console.log("No data available");
    }
    const fetchUserReducres = useSelector(state => state.fetchUserReducres);
    const signatures = useSelector((state) => state.fetchReducers);
    const hasSignature = signatures.data.some(signature => signature.signature_data);
    const FetchBankReducers = useSelector(state => state.FetchBankReducers);
    const [selectedReciptsId, setselectedReciptsId] = useState(null);
    const ref2 = useRef(null);
    const fetchRecipIdReducers = useSelector(state => state.fetchRecipIdReducers);
    const recipData = fetchRecipIdReducers.data;

    const email = fetchUserReducres.data ? fetchUserReducres.data.email : null;
    const company_name = fetchUserReducres.data ? fetchUserReducres.data.company_name : null;
    const phone_number = fetchUserReducres.data ? fetchUserReducres.data.phone_number : null;
    const phone_number_2 = fetchUserReducres.data ? fetchUserReducres.data.phone_number_2 : null;
    const company_website = fetchUserReducres.data ? fetchUserReducres.data.company_website : null;
    const company_GST = fetchUserReducres.data ? fetchUserReducres.data.company_GST : null;
    const company_add = fetchUserReducres.data ? fetchUserReducres.data.company_add : null;
    const FeatchTearmsReducers = useSelector(state => state.FeatchTearmsReducers);

    const { id } = useParams();
  useEffect(() => {
  const fetchDataRec = async () => {
    setLoading(true);
    try {
      const res = await dispatch(fatchReciptIDAPI(id));
      setLoading(false);
    } catch (error) {
        setLoading(false);
      console.error('Error fetching data:', error);
    }
  };
  fetchDataRec(); // Call the async function
}, [id]); // Include 'id' in the dependency array
  
    useEffect(() => {
        const fetchDataUser = async () => {
            try {
                const res = await dispatch(fatchUserAPI());
            } catch (error) {
                console.error('Error fetching Company Details data:', error);
            }
        };
        fetchDataUser();
    }, []);

    
    useEffect(() => {
        const fetchPhoto = async () => {
            try {
              const res = await dispatchFetchPhoto(fatchPhotoAPI());
                // Process the result if needed
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchPhoto(); // Call the async function
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await dispatch(fatchSignAPI());
                // Process the result if needed
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData(); // Call the async function
    }, []);

    useEffect(() => {
        const fetchDataTearms = async () => {
            try {
                const res = await dispatch(fatchTearmsAPI());
                // Process the result if needed
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchDataTearms(); // Call the async function
    }, []);
    useEffect(() => {
        const fetchBank = async () => {
            try {
                const res = await dispatch(fatchBankAPI());
                // Process the result if needed
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchBank(); // Call the async function
    }, []);

    const imageUrl = `https://app.packersbilling.in/api/${fetchcompanyLogo.data?.[0]?.path}`;
    const [base64URL, setBase64URL] = useState('');
    useEffect(() => {
        const fetchImage2 = async () => {
        try {
            const response = await fetch(imageUrl);
            if (!response.ok) {
            throw new Error(`Failed to fetch image. Status: ${response.status}`);
            }
            const blob = await response.blob();
            const reader = new FileReader();
            reader.onloadend = () => {
            const base64 = reader.result;
            setBase64URL(base64);
            };
            reader.readAsDataURL(blob);
        } catch (error) {
            console.error('Error fetching or converting the image:', error);
        }
        };

        fetchImage2();
    }, [imageUrl]);

      const handleDownloadPdf = () => {
        const element = document.getElementById('content-to-pdf');
        html2pdf(element, {
          margin: 10,
          filename: 'receipts.pdf',
          image: { type: 'jpeg', quality: 0.98 },
          html2canvas: { scale: 2 },
          jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
        });
      };
      
  return (
    <>
    {loading && <div className='text-center mt-4'>
        <Loader />
    </div>}
    {!loading && (
        <Row>
            {recipData && typeof recipData === 'object' && (
            <div className="container-fluid invoice-container"> 
                <div id='content-to-pdf'>
                    <header>
                    <div className="row align-items-center gy-3">
                        <div className="col-sm-3 text-center text-sm-start">
                                <div>
                                    {base64URL ? (
                                        <div>
                                            <img src={base64URL} alt="logo" className='logo'/>
                                        </div>
                                    ) : (
                                        <Link to="/update-company" className='btn btn-primary'>Add Logo</Link>
                                    )}
                                </div>
                        </div>
                        <div className="col-sm-9 text-center text-sm-end">
                            <h4 className="mb-1">{company_name}</h4>
                            <p className="mb-1"> <i className='fa fa-map-marker-alt'></i> {company_add}</p>
                            <div className='d-flex align-items-center justify-content-end mb-1'>
                                <p className="mb-0 mx-2 email"><i className='fa fa-envelope'></i> {email}</p>
                                <p className="mb-0"><i className='fa fa-phone'></i> +91 {phone_number} {phone_number_2 && ( // Check if phone_number_2 exists
                                    <span>, {phone_number_2}</span>
                                )}</p>
                            </div>
                            <div className='d-flex align-items-center justify-content-end'>
                                {company_GST && ( // Check if company_GST exists
                                    <p className="mb-0 mx-2"><i className='fa fa-file'></i> GSTIN : {company_GST}</p>
                                    )}
                                {company_website && ( // Check if company_GST exists
                                    <p className="mb-0 website"><i className='fa fa-globe-americas'></i> {company_website}</p>
                                )}
                            </div>
                        </div>
                    </div>
                    <hr/>
                    </header>
                    <main>
                    <div className="row gy-3 bg-red">
                    <div className="col mt-2 mb-2"> <strong>Receipt Number:</strong> <span>{recipData.receipt_number}</span> </div>
                    <div className="col text-sm-center mt-2 mb-2"> <strong>Service :</strong> <span>{recipData.service_name}</span> </div>
                    <div className="col text-sm-end mt-2 mb-2"> <strong>Receipt Date:</strong>  <span>
                    {(() => {
                        try {
                        return format(parseISO(recipData.recipts_date), 'dd-MM-yyyy');
                        } catch (error) {
                        console.error('Error parsing date:', recipData.recipts_date);
                        return 'Invalid Date';
                        }
                    })()}
                    </span> </div>
                    </div>
                    <hr className='mb-2 mt-0'/>
                    <div className="row">
                        <div className="col-sm-5">
                            <strong className='mb-1 d-block'>Received Payment With Thanks From M/s :</strong>
                            <p>{recipData.name}</p>
                        </div>
                        <div className="col-sm-3 text-end">
                            <strong className='mb-1 d-block'>Customer Mo :</strong>
                            <p>{recipData.phone_number}</p>
                        </div>

                        <div className="col-sm-2 text-end">
                            <strong className='mb-1 d-block'>Start Date :</strong>
                            <p>{(() => {
                                try {
                                return format(parseISO(recipData.shift_start_date), 'dd-MM-yyyy');
                                } catch (error) {
                                console.error('Error parsing date:', recipData.shift_start_date);
                                return 'Invalid Date';
                                }
                            })()}</p>
                        </div>

                        <div className="col-sm-2 text-end">
                            <strong className='mb-1 d-block'>End Date :</strong>
                            <p>{(() => {
                                try {
                                return format(parseISO(recipData.shift_end_date), 'dd-MM-yyyy');
                                } catch (error) {
                                console.error('Error parsing date:', recipData.shift_end_date);
                                return 'Invalid Date';
                                }
                            })()}</p>
                        </div>
                        
                    </div>
                    <div className="row">
                        <div className="col-sm-3">
                            <strong className='mb-1 d-block'>Payment Type :</strong>
                            <p>{recipData.payment_type}</p>
                        </div>
                        <div className="col-sm-3">
                            <strong className='mb-1 d-block'>From :</strong>
                            <p>{recipData.from_city}</p>
                        </div>

                        <div className="col-sm-3">
                            <strong className='mb-1 d-block'>To :</strong>
                            <p>{recipData.to_city}</p>
                        </div>
                    </div>
                    
                    <div class="table-responsive">
                        <table class="table border mb-0">
                            <thead>
                                <tr class="bg-light">
                                <td class="col-6"><strong>Description</strong></td>
                                <td class="col-2 text-end"><strong>Amount</strong></td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                <td class="col-6">Total Amount</td>
                                <td class="col-2 text-end"><i className='fa fa-indian-rupee'></i> {recipData.total_amount}</td>
                                </tr>
                                <tr>
                                <td>Received Amount</td>
                                <td class="text-end"><i className='fa fa-indian-rupee'></i> {recipData.received_amount}</td>
                                </tr>
                                <tr>
                                <td>Pending Amount</td>
                                <td class="text-end"><i className='fa fa-indian-rupee'></i> {recipData.pending_amount}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className='d-flex align-items-center justify-content-between'>
                                        <div>
                                            <h4 className="text-3 mt-4">Bank Details:</h4>
                                            {FetchBankReducers && Array.isArray(FetchBankReducers.data) && FetchBankReducers.data.length > 0 ? (
                                            [...FetchBankReducers.data].reverse().map(item => (
                                            <div key={item.id} class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-0">
                                                <div class="d-flex justify-content-between align-items-center mt-3">
                                                    <p class="text-dark profile_name">Account Holder Name :</p> <h6 class="text-dark profile_name_data mx-2">{item.account_holder_name}</h6>
                                                </div>
                                                <div class="d-flex justify-content-between align-items-center">
                                                    <p class="text-dark profile_name">Bank Name :</p>
                                                    <h6 class="text-dark profile_name_data mx-2">{item.bank_name}</h6>
                                                </div>
                                                <div class="d-flex justify-content-between align-items-center">
                                                    <p class="text-dark profile_name">Account Number:</p>
                                                    <h6 class="text-dark profile_name_data mx-2">{item.account_number}</h6>
                                                </div>
                                                <div class="d-flex justify-content-between align-items-center">
                                                    <p class="text-dark profile_name">IFFC Code :</p>
                                                    <h6 class="text-dark profile_name_data mx-2">{item.iffc_code}</h6>
                                                </div>
                                            </div>
                                            ))
                                            ) : (
                                                <div className='text-left not_data mt-3 mb-3'>
                                                    <Link to="/update-company" className='btn btn-primary'>Add Bank Details</Link>
                                                </div>
                                            )} 
                                        </div>
                                        <div className='text-end'>
                                            <h4 className="text-3 mt-4 stemp"><strong>For :</strong> {company_name}</h4>
                                            {[...signatures.data].map((signature, index) => (
                                                <img key={index} src={signature.signature_data} alt={`Signature ${index + 1}`} className='sign-size' />
                                            ))}
                                            {!hasSignature && (
                                                <Link to="/update-company" className='d-print-none btn btn-primary'>
                                                    Add Sign
                                                </Link>
                                            )}
                                            <p className='mb-0 stemp'>Authorized Signature</p>
                                        </div>
                                    </div>

                                    <div className='d-flex align-items-center justify-content-between'>
                                        <div>
                                            <h4 className="text-3 mt-4">Terms and conditions:</h4>
                                            {FeatchTearmsReducers && Array.isArray(FeatchTearmsReducers.data) && FeatchTearmsReducers.data.length > 0 ? (
                                            [...FeatchTearmsReducers.data].reverse().map(item => (
                                                <h6 className="text-dark profile_name_data_terms printpage" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(item.tearms) }} />
                                                ))
                                                ) : (
                                                    <div className='text-center not_data mt-3'>
                                                    <Link to="/update-company" className='d-print-none btn btn-primary mb-2 d-block'>Add Tearms+</Link>
                                                    </div>
                                                )} 
                                        </div>
                                    </div>
                    </main>
                </div>
            <div className="text-center invoice_design">
                {/* <button
                    onClick={() => item && UpdateReceiptClick(item._id)}
                    type="button"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#staticBackdrop"
                    aria-controls="staticBackdrop" className='btn btn-info bill_btn pt-2 pb-2'>
                    Edit
                </button> */}
                <div className="btn-group btn-group-sm d-print-none"> <a href="javascript:window.print()" className="btn btn-info quot_btn pt-2 pb-2"><i className="fa fa-print"></i> Print & Download</a>
                </div>
                <button
                    onClick={handleDownloadPdf}
                    type="button"
                    className='btn btn-info bill_btn pt-2 pb-2 d-print-none'>
                        Download as PDF
                    </button>
                <Link class="btn btn-info plus_btn text-white pt-2 pb-2 d-print-none" to="/receipts-list"><i className="fa fa-file"></i> All Recipts</Link>
            </div>
            
            </div>
            )}
        </Row>
        )}

    </>
  )
}

export default ReciptEditdesign

