import React, { useEffect, useState } from 'react';
import '../../css/billstyle.css'
import Logo from '../../images/logo.png'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Swal from 'sweetalert2';
import { fatchReciptAPI,fatchUserAPI,fatchPhotoAPI,fatchSignAPI,EditReciptAPI,fatchReciptIDAPI,fatchTearmsAPI,fatchBankAPI } from '../../redux/actionCreation';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { parseISO, format } from 'date-fns';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useRef } from 'react';
import 'react-quill/dist/quill.snow.css'; // Import the styles
import DOMPurify from 'dompurify';
import html2pdf from 'html2pdf.js';
import { Helmet } from 'react-helmet';
import Loader from '../Loader';

function Reciptdesign() {
    //view port set
    useEffect(() => {
        // Update the viewport meta tag when ABCComponent mounts
        const metaTag = document.querySelector('meta[name="viewport"]');
        if (metaTag) {
          metaTag.setAttribute('content', 'width=device-width, initial-scale=0.4, maximum-scale=1.0, user-scalable=yes');
        }
    
        // Clean up function to reset the viewport when ABCComponent unmounts
        return () => {
          if (metaTag) {
            metaTag.setAttribute('content', 'width=device-width, initial-scale=1');
          }
        };
      }, []); // Run only once on component mount
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [loading_update, setLoading_update] = useState(false);
    
    const dispatchFetchPhoto = useDispatch();
    const navigate = useNavigate();
    useEffect(()=>{
        if(!localStorage.getItem('tokan')){
            navigate('/login');
        }
    }, []);
    const fetchUserReducres = useSelector(state => state.fetchUserReducres);
    const fetchcompanyLogo = useSelector(state => state.fetchcompanyLogo);
    const signatures = useSelector((state) => state.fetchReducers);
    const hasSignature = signatures.data.some(signature => signature.signature_data);
    const FetchBankReducers = useSelector(state => state.FetchBankReducers);
    const [selectedReciptsId, setselectedReciptsId] = useState(null);

    const error_edit = useSelector(state => state.EditReciptReducrs.error?.edit_receipt_errors) ?? null;
    const ref2 = useRef(null);
    const email = fetchUserReducres.data ? fetchUserReducres.data.email : null;
    const company_name = fetchUserReducres.data ? fetchUserReducres.data.company_name : null;
    const phone_number = fetchUserReducres.data ? fetchUserReducres.data.phone_number : null;
    const phone_number_2 = fetchUserReducres.data ? fetchUserReducres.data.phone_number_2 : null;
    const company_website = fetchUserReducres.data ? fetchUserReducres.data.company_website : null;
    const company_GST = fetchUserReducres.data ? fetchUserReducres.data.company_GST : null;
    const company_add = fetchUserReducres.data ? fetchUserReducres.data.company_add : null;
    const FeatchTearmsReducers = useSelector(state => state.FeatchTearmsReducers);

    // Fetch Quotations Data
    useEffect(() => {
        const fetchDataUser = async () => {
            try {
                const res = await dispatch(fatchUserAPI());
            } catch (error) {
                console.error('Error fetching Company Details data:', error);
            }
        };
        fetchDataUser();
    }, []);

    useEffect(() => {
        const fetchPhoto = async () => {
            try {
              const res = await dispatchFetchPhoto(fatchPhotoAPI());
                // Process the result if needed
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchPhoto(); // Call the async function
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await dispatch(fatchSignAPI());
                // Process the result if needed
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData(); // Call the async function
    }, []);

    useEffect(() => {
        const fetchDataTearms = async () => {
            try {
                const res = await dispatch(fatchTearmsAPI());
                // Process the result if needed
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchDataTearms(); // Call the async function
    }, []);

    useEffect(() => {
        const fetchBank = async () => {
            try {
                const res = await dispatch(fatchBankAPI());
                // Process the result if needed
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchBank(); // Call the async function
    }, []);


    const imageUrl = `https://app.packersbilling.in/api/${fetchcompanyLogo.data?.[0]?.path}`;
    const [base64URL, setBase64URL] = useState('');
    useEffect(() => {
        const fetchImage2 = async () => {
        try {
            const response = await fetch(imageUrl);
            if (!response.ok) {
            throw new Error(`Failed to fetch image. Status: ${response.status}`);
            }
            const blob = await response.blob();
            const reader = new FileReader();
            reader.onloadend = () => {
            const base64 = reader.result;
            setBase64URL(base64);
            };
            reader.readAsDataURL(blob);
        } catch (error) {
            console.error('Error fetching or converting the image:', error);
        }
        };

        fetchImage2();
    }, [imageUrl]);

      const handleDownloadPdf = () => {
        const element = document.getElementById('content-to-pdf');
        html2pdf(element, {
          margin: 10,
          filename: 'receipts.pdf',
          image: { type: 'jpeg', quality: 0.98 },
          html2canvas: { scale: 2 },
          jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
        });
      };


    const fetchDataRecipst = useSelector(state => state.fetchRecipReducers);
         // Fetch Receipts Function Start
         useEffect(() => {
            const fetchData = async () => {
                setLoading(true);
                try {
                    const res = await dispatch(fatchReciptAPI());
                    setLoading(false);
                    // Process the result if needed
                } catch (error) {
                    setLoading(false);
                    console.error('Error fetching data:', error);
                }
            };
            fetchData(); // Call the async function
        }, []);
        // Fetch Receipts Function End
        const handleChange = (event) => {
            const { name, value } = event.target;
            setFormData(formData => ({
              ...formData,
              [name]: value
            }));
          };
        const [formData, setFormData] = useState({  
            name: '',
            phone_number: '',
            recipts_date: '',
            service_name: '',
            from_city: '',
            to_city: '',
            shift_start_date: '',
            shift_end_date: '',
            payment_type: '',
            total_amount: '',
            received_amount: ''
          });

          const formatDate = (dateString) => {
            const date = new Date(dateString);
            const formattedDate = date.toISOString().split('T')[0];
            return formattedDate;
        };

        // Function to handle link click
    const UpdateReceiptClick = (reciptId) => {
        setselectedReciptsId(reciptId);
        // Check if fetchDataRecipst.data is available and not empty
        if (fetchDataRecipst.data && fetchDataRecipst.data.length > 0) {
            const selectedRecipt = fetchDataRecipst.data.find(recipt => recipt.id === reciptId);

            if (selectedRecipt) {
                const formattedDate = formatDate(selectedRecipt.recipts_date);
                const shift_start_date = formatDate(selectedRecipt.shift_start_date);
                const shift_end_date = formatDate(selectedRecipt.shift_end_date);
                const defaultTime = new Date();
                defaultTime.setHours(0, 0, 0, 0);
    
                setFormData({
                    ...formData,
                    name: selectedRecipt.name,
                    phone_number: selectedRecipt.phone_number,
                    recipts_date: new Date(formattedDate + 'T00:00:00'),
                    service_name: selectedRecipt.service_name,
                    from_city: selectedRecipt.from_city,
                    to_city: selectedRecipt.to_city,
                    shift_start_date: new Date(shift_start_date + 'T00:00:00'),
                    shift_end_date: new Date(shift_end_date + 'T00:00:00'),
                    payment_type: selectedRecipt.payment_type,
                    total_amount: selectedRecipt.total_amount,
                    received_amount: selectedRecipt.received_amount
                });
            } else {
                console.log("Receipt not found for ID:", reciptId);
            }
        } else {
            console.log("No receipt data available");
        }
    };

    const UpdateRecipt = async (event) => {
        event.preventDefault();
        setLoading_update(true);
        try {
            const response = await dispatch(EditReciptAPI(formData, selectedReciptsId));
            setLoading_update(false);
            if(response?.type == "ADD_DATA_SUCCESS_EDIT_RECEIPT"){
                ref2.current.click();
                await Swal.fire({
                    icon: 'success',
                    title: 'Success!',
                    text: 'Receipt updated successfully.',
                    customClass: {
                        container: 'my-custom-swal', // Add your custom class here
                    },
                });
                dispatch(fatchReciptAPI());
            }
              else {
              // Show error SweetAlert
                await Swal.fire({
                    icon: 'error',
                    title: 'Some Error',
                    text: 'Failed to update the Receipt. Please try again.',
                });
            }
              
        } catch (error) {
          console.error('Error updating bill:', error);
        }
      };

    const handleReceiptDateChange = (date) => {
        // Create a new Date object with the same values to avoid modifying the original object
        const modifiedDate = new Date(date);
        modifiedDate.setHours(0, 0, 0, 0);
    
        // Format the date to "dd-mm-yyyy"
        const day = String(modifiedDate.getDate()).padStart(2, '0');
        const month = String(modifiedDate.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        const year = String(modifiedDate.getFullYear());
    
        const formattedDate = `${day}-${month}-${year}`;
    
        console.log("date", formattedDate);
        // Update the recipts_date field in formData when the DatePicker changes
        setFormData((prevState) => ({
            ...prevState,
            recipts_date: modifiedDate,
        }));
    };
    
    const handleStartDateChange = (date) => {
        const modifiedDate = new Date(date);
        modifiedDate.setHours(0, 0, 0, 0);
        // Format the date to "dd-mm-yyyy"
        const day = String(modifiedDate.getDate()).padStart(2, '0');
        const month = String(modifiedDate.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        const year = String(modifiedDate.getFullYear());
    
        const formattedDate = `${day}-${month}-${year}`;
        console.log("date", formattedDate);
        // Update the recipts_date field in formData when the DatePicker changes
        setFormData((prevState) => ({
            ...prevState,
            shift_start_date: modifiedDate,
        }));
      };

      const handleEndDateChange = (date) => {
        const modifiedDate = new Date(date);
        modifiedDate.setHours(0, 0, 0, 0);
        // Format the date to "dd-mm-yyyy"
        const day = String(modifiedDate.getDate()).padStart(2, '0');
        const month = String(modifiedDate.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        const year = String(modifiedDate.getFullYear());
    
        const formattedDate = `${day}-${month}-${year}`;
        console.log("date", formattedDate);
        // Update the recipts_date field in formData when the DatePicker changes
        setFormData((prevState) => ({
            ...prevState,
            shift_end_date: modifiedDate,
        }));
      };
   
  return (
    <>
    {loading && <div className='text-center mt-4'>
        <Loader />
    </div>}
    {!loading && (
    <Row>
        {fetchDataRecipst.data && Array.isArray(fetchDataRecipst.data) && fetchDataRecipst.data.length > 0 ? (
            [...fetchDataRecipst.data].reverse().slice(0,1).map(item => (
            <div key={item.id} className="container-fluid invoice-container"> 
                <div id='content-to-pdf'>
                    <header>
                        <div className="row align-items-center gy-3">
                            <div className="col-sm-3 text-center text-sm-start">
                            <div>
                                {base64URL ? (
                                    <div>
                                    <img src={base64URL} alt="logo" className='logo'/>
                                    </div>
                                ) : (
                                    <Link to="/update-company" className='btn btn-primary'>Add Logo</Link>
                                )}
                            </div>
                            {/* {fetchcompanyLogo.data.path ? (
                                    <img
                                        id="logo"
                                        src={`https://app.packersbilling.in/${fetchcompanyLogo.data.path}`}
                                        title="Koice"
                                        alt="Koice"
                                        className='logo'
                                    />
                                ) : (
                                    <Link to="/update-company" className='d-print-none btn btn-primary'>
                                        Add Logo
                                    </Link>
                                )} */}
                            </div>
                            <div className="col-sm-9 text-center text-sm-end">
                                <h4 className="mb-1">{company_name}</h4>
                                <p className="mb-1"> <i className='fa fa-map-marker-alt'></i> {company_add}</p>
                                <div className='d-flex align-items-center justify-content-end mb-1'>
                                    <p className="mb-0 mx-2 email"><i className='fa fa-envelope'></i> {email}</p>
                                    <p className="mb-0"><i className='fa fa-phone'></i> +91 {phone_number} {phone_number_2 && ( // Check if phone_number_2 exists
                                    <span>, {phone_number_2}</span>
                                    )}</p>
                                </div>
                                <div className='d-flex align-items-center justify-content-end'>
                                    {company_GST && ( // Check if company_GST exists
                                        <p className="mb-0 mx-2"><i className='fa fa-file'></i> GSTIN : {company_GST}</p>
                                    )}
                                    {company_website && ( // Check if company_GST exists
                                        <p className="mb-0 website"><i className='fa fa-globe-americas'></i> {company_website}</p>
                                    )}
                                </div>
                            </div>
                        </div>
                        <hr/>
                    </header>
                    <main>
                        <div className="row gy-3 bg-red">
                        <div className="col mt-2 mb-2"> <strong>Receipt Number:</strong> <span>{item.receipt_number}</span> </div>
                        <div className="col text-sm-center mt-2 mb-2"> <strong>Service :</strong> <span>{item.service_name}</span> </div>
                        <div className="col text-sm-end mt-2 mb-2"> <strong>Receipt Date:</strong> <span>{format(parseISO(item.recipts_date), 'dd-MM-yyyy')}</span> </div>
                        </div>
                        <hr className='mb-2 mt-0'/>
                        <div className="row">
                            <div className="col-sm-5">
                                <strong className='mb-1 d-block'>Received Payment With Thanks From M/s :</strong>
                                <p>{item.name}</p>
                            </div>
                            <div className="col-sm-3 text-end">
                                <strong className='mb-1 d-block'>Customer Mo :</strong>
                                <p>{item.phone_number}</p>
                            </div>

                            <div className="col-sm-2 text-end">
                                <strong className='mb-1 d-block'>Start Date :</strong>
                                <p>{format(parseISO(item.shift_start_date), 'dd-MM-yyyy')}</p>
                            </div>

                            <div className="col-sm-2 text-end">
                                <strong className='mb-1 d-block'>End Date :</strong>
                                <p>{format(parseISO(item.shift_end_date), 'dd-MM-yyyy')}</p>
                            </div>
                            
                        </div>
                        <div className="row">
                            <div className="col-sm-3">
                                <strong className='mb-1 d-block'>Payment Type :</strong>
                                <p>{item.payment_type}</p>
                            </div>
                            <div className="col-sm-3">
                                <strong className='mb-1 d-block'>From :</strong>
                                <p>{item.from_city}</p>
                            </div>

                            <div className="col-sm-3">
                                <strong className='mb-1 d-block'>To :</strong>
                                <p>{item.to_city}</p>
                            </div>
                        </div>
                        
                        <div class="table-responsive">
                            <table class="table border mb-0">
                                <thead>
                                    <tr class="bg-light">
                                    <td class="col-6"><strong>Description</strong></td>
                                    <td class="col-2 text-end"><strong>Amount</strong></td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                    <td class="col-6">Total Amount</td>
                                    <td class="col-2 text-end"><i className='fa fa-indian-rupee'></i> {item.total_amount}</td>
                                    </tr>
                                    <tr>
                                    <td>Received Amount</td>
                                    <td class="text-end"><i className='fa fa-indian-rupee'></i> {item.received_amount}</td>
                                    </tr>
                                    <tr>
                                    <td>Pending Amount</td>
                                    <td class="text-end"><i className='fa fa-indian-rupee'></i> {item.pending_amount}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className='d-flex align-items-center justify-content-between'>
                            <div>
                            <h4 className="text-3 mt-4">Bank Details:</h4>
                                {FetchBankReducers && Array.isArray(FetchBankReducers.data) && FetchBankReducers.data.length > 0 ? (
                                [...FetchBankReducers.data].reverse().map(item => (
                                <div key={item.id} class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-0">
                                    
                                    <div class="d-flex justify-content-between align-items-center mt-3">
                                        <p class="text-dark profile_name">Account Holder Name :</p> <h6 class="text-dark profile_name_data mx-2">{item.account_holder_name}</h6>
                                    </div>
                                    <div class="d-flex justify-content-between align-items-center">
                                        <p class="text-dark profile_name">Bank Name :</p>
                                        <h6 class="text-dark profile_name_data mx-2">{item.bank_name}</h6>
                                    </div>
                                    <div class="d-flex justify-content-between align-items-center">
                                        <p class="text-dark profile_name">Account Number:</p>
                                        <h6 class="text-dark profile_name_data mx-2">{item.account_number}</h6>
                                    </div>
                                    <div class="d-flex justify-content-between align-items-center">
                                        <p class="text-dark profile_name">IFFC Code :</p>
                                        <h6 class="text-dark profile_name_data mx-2">{item.iffc_code}</h6>
                                    </div>
                                </div>
                                ))
                                ) : (
                                    <div className='text-left not_data mt-3 mb-3'>
                                        <Link to="/update-company" className='btn btn-primary'>Add Bank Details</Link>
                                    </div>
                                )} 
                            </div>
                            <div className='text-end'>
                                <h4 className="text-3 mt-4 stemp"><strong>For :</strong> {company_name}</h4>
                                {[...signatures.data].map((signature, index) => (
                                    <img key={index} src={signature.signature_data} alt={`Signature ${index + 1}`} className='sign-size' />
                                ))}
                                {!hasSignature && (
                                    <Link to="/update-company" className='d-print-none btn btn-primary'>
                                        Add Sign
                                    </Link>
                                )}
                                <p className='mb-0 stemp'>Authorized Signature</p>
                            </div>
                        </div>

                        <div className='d-flex align-items-center justify-content-between'>
                            <div>
                                <h4 className="text-3 mt-4">Terms and conditions:</h4>
                                {FeatchTearmsReducers && Array.isArray(FeatchTearmsReducers.data) && FeatchTearmsReducers.data.length > 0 ? (
                                [...FeatchTearmsReducers.data].reverse().map(item => (
                                    <h6 className="text-dark profile_name_data_terms printpage" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(item.tearms) }} />
                                    ))
                                    ) : (
                                        <div className='text-center not_data mt-3'>
                                        <Link to="/update-company" className='mb-2 d-block d-print-none btn btn-primary'>Add Tearms+</Link>
                                        </div>
                                    )} 
                            </div>
                        </div>

                    </main>
                </div>
                <div className="text-center invoice_design">
                    <button
                        onClick={() => item && UpdateReceiptClick(item.id)}
                        type="button"
                        data-bs-toggle="offcanvas"
                        data-bs-target="#staticBackdrop"
                        aria-controls="staticBackdrop" className='btn btn-info bill_btn pt-2 pb-2  d-print-none'>
                        Edit
                    </button>
                    <div className="btn-group btn-group-sm d-print-none">
                        <a href="javascript:window.print()" className="btn btn-info quot_btn pt-2 pb-2"><i className="fa fa-print"></i> Print & Download</a> 
                    </div>
                    <button
                        onClick={handleDownloadPdf}
                        type="button"
                        className='btn btn-info bill_btn pt-2 pb-2 d-print-none'>
                        Download as PDF
                    </button>
                    <Link class="btn btn-info plus_btn text-white pt-2 pb-2 d-print-none" to="/receipts-list">Done</Link>
                </div>
            </div>
        ))
        ): (
            <div className='text-center not_data mt-3'>
                <i class="fa-solid fa-triangle-exclamation"></i>
                <p>No data available</p>
            </div>
        )}
    </Row>
    )}
        {/* Edit Receipts Sidebar Start*/}
        <div class="offcanvas offcanvas-start" data-bs-backdrop="static" tabIndex="-1" id="staticBackdrop" aria-labelledby="staticBackdropLabel" style={{ zIndex: 99999999999 }}>
                    <div class="offcanvas-header bg-info">
                        <h5 class="offcanvas-title text-white" id="staticBackdropLabel">Edit Receipt</h5>
                        <button type="button" ref={ref2} class="btn-close btn-close2" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                    </div>
                    <div class="offcanvas-body">
                        <div>
                            <Form onSubmit={UpdateRecipt}>
                                    <Row className="mb-3">
                                        <h6 className='mb-0'>General Details:</h6>
                                        <Col xs="12" sm="12" md="12" lg="12" xl="12" xxl="12">
                                            <hr className='w-10'/>
                                        </Col>

                                        <Form.Group as={Col} xs="12" sm="12" md="12" lg="12" xl="12" xxl="12" controlId="formGridEmail" className='mt-2'>
                                            <Form.Label>Customer Name</Form.Label>
                                            <Form.Control type="text" onChange={handleChange} placeholder="Customer Name.." name="name" value={formData.name} required/>
                                            {error_edit?.name && (
                                            <span className="alert alert-danger d-block mt-1 mb-1 pt-1 pb-1">
                                            {error_edit.name && error_edit.name}
                                            </span>
                                            )}
                                        </Form.Group>

                                        <Form.Group as={Col} xs="12" sm="12" md="12" lg="12" xl="12" xxl="12" controlId="formGridPassword" className='mt-2'>
                                            <Form.Label>Phone</Form.Label>
                                            <Form.Control type="number" name="phone_number" onChange={handleChange} placeholder="Customer Phone.." value={formData.phone_number}  required/>

                                            {error_edit?.phone_greater && (
                                            <span className="alert alert-danger d-block mt-1 mb-1 pt-1 pb-1">
                                            {error_edit.phone_greater && error_edit.phone_greater}
                                            </span>
                                            )}

                                            {error_edit?.phone_less && (
                                            <span className="alert alert-danger d-block mt-1 mb-1 pt-1 pb-1">
                                            {error_edit.phone_less && error_edit.phone_less}
                                            </span>
                                            )}
                                        </Form.Group>

                                        <Form.Group as={Col} xs="12" sm="12" md="6" lg="6" xl="6" xxl="6" controlId="formGridPassword" className='mt-2'>
                                            <Form.Label>Recipt Date</Form.Label>
                                                <div className="date-picker-container">
                                                    <i className='calendar-icon fa fa-calendar'></i>
                                                    <DatePicker
                                                        selected={formData.recipts_date}
                                                        onChange={handleReceiptDateChange}
                                                        dateFormat="dd-MM-yyyy"
                                                        placeholderText="Receipt Date"
                                                        className='form-control'
                                                        required
                                                    />
                                                </div>
                                        </Form.Group>

                                        <Form.Group as={Col} xs="12" sm="12" md="6" lg="6" xl="6" xxl="6" controlId="formGridEmail" className='mt-2'>
                                                <Form.Label>Service Name</Form.Label>
                                                <Form.Control type="text" placeholder="Service Name.." onChange={handleChange}
                                                value={formData.service_name} name="service_name" required/>
                                        </Form.Group>

                                        <Form.Group as={Col} xs="12" sm="12" md="6" lg="6" xl="6" xxl="6" controlId="formGridEmail" className='mt-2'>
                                                <Form.Label>From City/Area</Form.Label>
                                                <Form.Control type="text" onChange={handleChange} placeholder="From.." name="from_city" value={formData.from_city}  required/>
                                        </Form.Group>

                                        <Form.Group as={Col} xs="12" sm="12" md="6" lg="6" xl="6" xxl="6" controlId="formGridEmail" className='mt-2'>
                                            <Form.Label>To City/Area</Form.Label>
                                            <Form.Control type="text" onChange={handleChange} placeholder="To.." value={formData.to_city} name="to_city" required/>
                                        </Form.Group>

                                        <h6 className='mb-0 mt-3'>Shifting Charges :</h6>
                                        <Col xs="12" sm="12" md="12" lg="12" xl="12" xxl="12">
                                            <hr className='w-10'/>
                                        </Col>

                                        <Form.Group as={Col} xs="12" sm="12" md="6" lg="6" xl="6" xxl="6" controlId="formGridPassword" className='mt-2'>
                                            <Form.Label>Shifting Start Date</Form.Label>
                                                <div className="date-picker-container">
                                                    <i className='calendar-icon fa fa-calendar'></i>
                                                    <DatePicker
                                                        selected={formData.shift_start_date}
                                                        onChange={handleStartDateChange}
                                                        dateFormat="dd-MM-yyyy"
                                                        placeholderText="Start Date"
                                                        className='form-control'
                                                        required
                                                    />
                                                </div>
                                        </Form.Group>

                                        <Form.Group as={Col} xs="12" sm="12" md="6" lg="6" xl="6" xxl="6" controlId="formGridPassword" className='mt-2'>
                                            <Form.Label>Shifting End Date</Form.Label>
                                                <div className="date-picker-container">
                                                    <i className='calendar-icon fa fa-calendar'></i>
                                                    <DatePicker
                                                        selected={formData.shift_end_date}
                                                        onChange={handleEndDateChange}
                                                        dateFormat="dd-MM-yyyy"
                                                        placeholderText="End Date"
                                                        className='form-control'
                                                        required
                                                    />
                                                </div>
                                                {error_edit?.shift_end_date_final && (
                                                <span className="alert alert-danger d-block mt-1 mb-1 pt-1 pb-1">
                                                {error_edit.shift_end_date_final && error_edit.shift_end_date_final}
                                                </span>
                                                )}
                                        </Form.Group>

                                        <Form.Group as={Col} xs="12" sm="12" md="12" lg="12" xl="12" xxl="12" controlId="formGridPassword" className='mt-2'>
                                            <Form.Label>Payment Type</Form.Label>
                                            <Form.Select aria-label="Default select example" onChange={handleChange} name="payment_type" value={formData.payment_type}>
                                                <option value="cash">Cash</option>
                                                <option value="online">Online</option>
                                                <option value="Cheque">Cheque</option>
                                            </Form.Select>
                                        </Form.Group>

                                        <Form.Group as={Col} xs="12" sm="12" md="6" lg="6" xl="6" xxl="6" controlId="formGridPassword" className='mt-2'>
                                            <Form.Label>Total Amount</Form.Label>
                                            <Form.Control type="number" placeholder="Total Amount.." onChange={handleChange} value={formData.total_amount} name="total_amount" required/>
                                        </Form.Group>

                                        <Form.Group as={Col} xs="12" sm="12" md="6" lg="6" xl="6" xxl="6" controlId="formGridPassword" className='mt-2'>
                                            <Form.Label>Received Amount</Form.Label>
                                            <Form.Control type="number" onChange={handleChange} placeholder="Received Amount" name="received_amount" value={formData.received_amount} required/>
                                            {error_edit?.received_amount && (
                                            <span className="alert alert-danger d-block mt-1 pt-1 pb-1">
                                            {error_edit.received_amount && error_edit.received_amount}
                                            </span>
                                            )}
                                        </Form.Group>
                                    </Row>

                                    <Button variant="primary" type="submit" className='d-flex align-items-center justify-content-center'>
                                    <span className='mx-2'>Update</span>
                                    {loading_update && <span className='spin-sm'><Loader /></span>}
                                    </Button>
                                </Form>
                        </div>
                    </div>
        </div>
        {/* Edit Receipts Sidebar End */}
    </>
  )
}

export default Reciptdesign

