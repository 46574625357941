import React, { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {useDispatch, useSelector } from 'react-redux';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Swal from 'sweetalert2';
import FixedButton from '../FixedButton';
import MobileNavigation from '../MobileNavigation';
import { fatchReciptAPI,DeleteReciptsAPI,EditReciptAPI } from '../../redux/actionCreation';
import DatePicker from 'react-datepicker';
import { parseISO, format } from 'date-fns';
import 'react-datepicker/dist/react-datepicker.css';
import { Link, useNavigate } from 'react-router-dom';
import { useRef } from 'react';
import Loader from '../Loader';

function ReceiptsList() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [loading_update, setLoading_update] = useState(false);
    
    useEffect(()=>{
        if(!localStorage.getItem('tokan')){
            navigate('/login');
        }
    }, []);
    const sidebarState = useSelector(state => state.sidebarReducres);
    const fetchDataRecipst = useSelector(state => state.fetchRecipReducers);
    const fetchRecipIdReducers = useSelector(state => state.fetchRecipIdReducers);
    const refEdit = useRef(null);
    const ref2 = useRef(null);
    const error_edit = useSelector(state => state.EditReciptReducrs.error?.edit_receipt_errors) ?? null;
    const [selectedReciptsId, setselectedReciptsId] = useState(null);
    const [formdate, setFormDate] = useState(null);
    const [todate, setToDate] = useState(null);
    const [customerNumber, setCustomerNumber] = useState('');
    const [customerName, setCustomerName] = useState('');
    const [filteredReceipts, setFilteredReceipts] = useState([]);
    const dispatch = useDispatch();
    let receiptCounter = 0;

    // Datewise Filter Code Start
    const fromDate = (date) => {
        setFormDate(date);
      };
      const toDate = (date) => {
        setToDate(date);
      };

      // Fetch Receipts Function Start
    

      useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const res = await dispatch(fatchReciptAPI());
                setLoading(false);
            } catch (error) {
                setLoading(false);
                console.error('Error fetching data:', error);
            }
        };
        fetchData(); // Call the async function
    }, []);
    // Fetch Receipts Function End

       useEffect(() => {
            setFilteredReceipts(fetchDataRecipst.data);
      }, [fetchDataRecipst.data]); 
      
      // Datewise Filter Code Start
      const handleFormSubmit = (event) => {
        event.preventDefault();
        const newFilteredReceipts = fetchDataRecipst.data.filter((item) => {
            
            // Check date range
            const itemDate = new Date(item.recipts_date);
            // Function to format the date as dd-mm-yyyy
            function formatDate(date) {
            const day = String(date.getDate()).padStart(2, '0');
            const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
            const year = date.getFullYear();
            return `${day}-${month}-${year}`;
            }
            const formattedDate = formatDate(itemDate);

            const isWithinRange =
            (!formdate || itemDate >= formdate) &&
            (!todate || itemDate <= new Date(todate.getTime() + 86400000)); // 86400000 milliseconds in a day

            // Check other criteria
            const itemNumber = item.phone_number;
            const matchesCustomerNumber = !customerNumber || itemNumber === customerNumber;  // Corrected variable name
            const matchesCustomerName = !customerName || (item.customerName && item.customerName.toLowerCase().includes(customerName.toLowerCase()));
    
            return isWithinRange && matchesCustomerNumber && matchesCustomerName;
        });
        setFilteredReceipts(newFilteredReceipts);
    };
    // Datewise Filter Code End

    // Update Recipts Function Start
    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData(formData => ({
          ...formData,
          [name]: value
        }));
      };

    const [formData, setFormData] = useState({  
        name: '',
        phone_number: '',
        recipts_date: '',
        service_name: '',
        from_city: '',
        to_city: '',
        shift_start_date: '',
        shift_end_date: '',
        payment_type: '',
        total_amount: '',
        received_amount: ''
      });

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const formattedDate = date.toISOString().split('T')[0];
        return formattedDate;
    };

    // Function to handle link click
    const UpdateReceiptClick = (reciptId) => {
        setselectedReciptsId(reciptId);
        // Check if fetchDataRecipst.data is available and not empty
        if (fetchDataRecipst.data && fetchDataRecipst.data.length > 0) {
            const selectedRecipt = fetchDataRecipst.data.find(recipt => recipt.id === reciptId);

            if (selectedRecipt) {
                const formattedDate = formatDate(selectedRecipt.recipts_date);
                const shift_start_date = formatDate(selectedRecipt.shift_start_date);
                const shift_end_date = formatDate(selectedRecipt.shift_end_date);
                const defaultTime = new Date();
                defaultTime.setHours(0, 0, 0, 0);
    
                setFormData({
                    ...formData,
                    name: selectedRecipt.name,
                    phone_number: selectedRecipt.phone_number,
                    recipts_date: new Date(formattedDate + 'T00:00:00'),
                    service_name: selectedRecipt.service_name,
                    from_city: selectedRecipt.from_city,
                    to_city: selectedRecipt.to_city,
                    shift_start_date: new Date(shift_start_date + 'T00:00:00'),
                    shift_end_date: new Date(shift_end_date + 'T00:00:00'),
                    payment_type: selectedRecipt.payment_type,
                    total_amount: selectedRecipt.total_amount,
                    received_amount: selectedRecipt.received_amount
                });
            } else {
                console.log("Receipt not found for ID:", reciptId);
            }
        } else {
            console.log("No receipt data available");
        }
    };

    const UpdateRecipt = async (event) => {
        event.preventDefault();
        setLoading_update(true);
        try {
            const response = await dispatch(EditReciptAPI(formData, selectedReciptsId));
            setLoading_update(false);
            if(response?.type == "ADD_DATA_SUCCESS_EDIT_RECEIPT"){
                ref2.current.click();
                await Swal.fire({
                    icon: 'success',
                    title: 'Success!',
                    text: 'Receipt updated successfully.',
                    customClass: {
                        container: 'my-custom-swal', // Add your custom class here
                    },
                });
                dispatch(fatchReciptAPI());
            }
              else {
              // Show error SweetAlert
                await Swal.fire({
                    icon: 'error',
                    title: 'Some Error',
                    text: 'Failed to update the Receipt. Please try again.',
                });
            }
              
        } catch (error) {
          console.error('Error updating bill:', error);
        }
      };
      // Update Recipts Function End

         // Delete Quotation Function
         const DeleteRecipt = async (reciptsId) => {
            // Show confirmation SweetAlert
            const result = await Swal.fire({
                title: `Are you sure Delete Receipt ?`,
                text: 'You won\'t be able to revert this!',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#673ab7',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!',
            });
        
            if (result.isConfirmed) {
                try {
                    await dispatch(DeleteReciptsAPI(reciptsId));
                    dispatch(fatchReciptAPI());
                } catch (error) {
                //   console.error('Error:', error);
                }
            } else {
                // User clicked "Cancel" or closed the SweetAlert
                console.log('Deletion cancelled.');
            }
        };

        const handleReceiptDateChange = (date) => {
            // Create a new Date object with the same values to avoid modifying the original object
            const modifiedDate = new Date(date);
            modifiedDate.setHours(0, 0, 0, 0);
        
            // Format the date to "dd-mm-yyyy"
            const day = String(modifiedDate.getDate()).padStart(2, '0');
            const month = String(modifiedDate.getMonth() + 1).padStart(2, '0'); // Months are zero-based
            const year = String(modifiedDate.getFullYear());
        
            const formattedDate = `${day}-${month}-${year}`;
        
            console.log("date", formattedDate);
            // Update the recipts_date field in formData when the DatePicker changes
            setFormData((prevState) => ({
                ...prevState,
                recipts_date: modifiedDate,
            }));
        };

        const handleStartDateChange = (date) => {
            const modifiedDate = new Date(date);
            modifiedDate.setHours(0, 0, 0, 0);
            // Format the date to "dd-mm-yyyy"
            const day = String(modifiedDate.getDate()).padStart(2, '0');
            const month = String(modifiedDate.getMonth() + 1).padStart(2, '0'); // Months are zero-based
            const year = String(modifiedDate.getFullYear());
        
            const formattedDate = `${day}-${month}-${year}`;
            console.log("date", formattedDate);
            // Update the recipts_date field in formData when the DatePicker changes
            setFormData((prevState) => ({
                ...prevState,
                shift_start_date: modifiedDate,
            }));
          };
    
          const handleEndDateChange = (date) => {
            const modifiedDate = new Date(date);
            modifiedDate.setHours(0, 0, 0, 0);
            // Format the date to "dd-mm-yyyy"
            const day = String(modifiedDate.getDate()).padStart(2, '0');
            const month = String(modifiedDate.getMonth() + 1).padStart(2, '0'); // Months are zero-based
            const year = String(modifiedDate.getFullYear());
        
            const formattedDate = `${day}-${month}-${year}`;
            console.log("date", formattedDate);
            // Update the recipts_date field in formData when the DatePicker changes
            setFormData((prevState) => ({
                ...prevState,
                shift_end_date: modifiedDate,
            }));
          };


  return (
    <>
        <FixedButton/>
        <div className={`main-content ${!sidebarState ? '' : 'navbar-ful'}`}>
            <div className='page-content'>
                <Container fluid>
                    <Row>
                        <Col xs="12" sm="12" md="12" lg="12" xl="12" xxl="12">
                            <h5 className='mb-1'>Search Receipts</h5>
                        </Col>
                    </Row>
                    <Form onSubmit={handleFormSubmit}>
                        <Row className='search-list'>
                            <Col xs="6" sm="6" md="2" lg="2" xl="2" xxl="2" className="mt-2">
                                <div className="date-picker-container">
                                    <i className='calendar-icon fa fa-calendar'></i>
                                    <DatePicker
                                        selected={formdate}
                                        onChange={fromDate}
                                        dateFormat="dd/MM/yyyy"
                                        placeholderText="From Date"
                                        className='form-control'
                                    />
                                </div>
                            </Col>

                            <Col xs="6" sm="6" md="2" lg="2" xl="2" xxl="2" className="mt-2">
                                <div className="date-picker-container">
                                    <i className='calendar-icon fa fa-calendar'></i>
                                    <DatePicker
                                        selected={todate}
                                        onChange={toDate}
                                        dateFormat="dd/MM/yyyy"
                                        placeholderText="To Date"
                                        className='form-control'
                                    />
                                </div>
                            </Col>
                            
                            <Col xs="6" sm="6" md="2" lg="2" xl="2" xxl="2" className="mt-2">
                                <Button variant="primary" type="submit" className="">
                                    Submit
                                </Button>
                            </Col>
                        </Row>
                    </Form>

                    <Row>
                        <Col xs="12" sm="12" md="12" lg="12" xl="12" xxl="12" className='mt-4'>
                            <h5 className='mb-3'>Receipts List</h5>
                        </Col>
                    </Row>
                    <div className='text-center'>
                        {loading && <Loader />}
                    </div>
                    {!loading && (
                    <Row>
                    {filteredReceipts && Array.isArray(filteredReceipts) && filteredReceipts.length > 0 ? (
                    [...filteredReceipts].reverse().map(item => (
                        <Col key={item.id} xs="12" sm="12" md="6" lg="6" xl="6" xxl="6" className='d-flex'>
                            <div className="dash-count bill_box das3">
                                <div className="dash-counts">
                                    <h4 className="text-dark c_name">{item.name}</h4>
                                    <h5 className="text-dark text-green">Receipt #<span>{item.receipt_number}</span>
                                    </h5>
                                    {item.recipts_date && (
                                    <h5 className="text-dark">
                                        {format(parseISO(item.recipts_date), 'dd-MM-yyyy')}
                                    </h5>
                                    )}
                                </div>
                                <div className="dash-imgs">
                                    <i className="text-dark invoice_i fa fa-indian-rupee-sign mr-0"></i>
                                    <span className="amount text-dark">{item.total_amount}</span>
                                    {item.pending_amount !== 0 && (
                                        <span className="pending_amount text-dark">
                                            Unpaid <i className="text-dark fa fa-indian-rupee-sign mr-0"></i> {item.pending_amount}
                                        </span>
                                    )}
                                    <p>
                                        <Link to={`/recipteedit/${item.id}`}>
                                            <i className='view_invoice_i pr-3 fa fa-eye'></i>
                                        </Link>

                                        {/* ${receiptId} */}
                                        <Link
                                        onClick={() => item && UpdateReceiptClick(item.id)}
                                        type="button"
                                        data-bs-toggle="offcanvas"
                                        data-bs-target="#staticBackdrop"
                                        aria-controls="staticBackdrop">
                                        <i className='view_invoice_i pr-3 fa fa-edit'></i>
                                        </Link>

                                        <Link to="#" onClick={() => DeleteRecipt(item.id)}>
                                            <i className='delet_invoice_i pr-3 fa fa-trash'></i>
                                        </Link>
                                    </p>
                                </div>
                            </div>
                        </Col>
                        ))
                        ) : (
                            <div className='text-center not_data mt-3'>
                                <i class="fa-solid fa-triangle-exclamation"></i>
                                <p>No data available</p>
                            </div>
                        )}
                         {/* All Recupts */}
                    </Row>
                    )}
                </Container>
            </div>
            <MobileNavigation/>

            {/* Edit Receipts Sidebar Start*/}
            <div class="offcanvas offcanvas-start" data-bs-backdrop="static" tabIndex="-1" id="staticBackdrop" aria-labelledby="staticBackdropLabel" style={{ zIndex: 99999999999 }}>
                <div class="offcanvas-header bg-info">
                    <h5 class="offcanvas-title text-white" id="staticBackdropLabel">Edit Receipt</h5>
                    <button type="button" ref={ref2} class="btn-close btn-close2" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                </div>
                <div class="offcanvas-body">
                    <div>
                        <Form onSubmit={UpdateRecipt}>
                                <Row className="mb-3">
                                    <h6 className='mb-0'>General Details:</h6>
                                    <Col xs="12" sm="12" md="12" lg="12" xl="12" xxl="12">
                                         <hr className='w-10'/>
                                    </Col>

                                    <Form.Group as={Col} xs="12" sm="12" md="12" lg="12" xl="12" xxl="12" controlId="formGridEmail" className='mt-2'>
                                        <Form.Label>Customer Name</Form.Label>
                                        <Form.Control type="text" onChange={handleChange} placeholder="Customer Name.." name="name" value={formData.name} required/>
                                        {error_edit?.name && (
                                        <span className="alert alert-danger d-block mt-1 mb-1 pt-1 pb-1">
                                        {error_edit.name && error_edit.name}
                                        </span>
                                        )}
                                    </Form.Group>

                                    <Form.Group as={Col} xs="12" sm="12" md="12" lg="12" xl="12" xxl="12" controlId="formGridPassword" className='mt-2'>
                                        <Form.Label>Phone</Form.Label>
                                        <Form.Control type="number" min={0} name="phone_number" onChange={handleChange} placeholder="Customer Phone.." value={formData.phone_number}  required/>

                                        {error_edit?.phone_greater && (
                                        <span className="alert alert-danger d-block mt-1 mb-1 pt-1 pb-1">
                                        {error_edit.phone_greater && error_edit.phone_greater}
                                        </span>
                                        )}

                                        {error_edit?.phone_less && (
                                        <span className="alert alert-danger d-block mt-1 mb-1 pt-1 pb-1">
                                        {error_edit.phone_less && error_edit.phone_less}
                                        </span>
                                        )}
                                    </Form.Group>

                                    <Form.Group as={Col} xs="12" sm="12" md="6" lg="6" xl="6" xxl="6" controlId="formGridPassword" className='mt-2'>
                                        <Form.Label>Recipt Date</Form.Label>
                                            <div className="date-picker-container">
                                                <i className='calendar-icon fa fa-calendar'></i>
                                                <DatePicker
                                                    selected={formData.recipts_date}
                                                    onChange={handleReceiptDateChange}
                                                    dateFormat="dd-MM-yyyy"
                                                    placeholderText="Receipt Date"
                                                    className='form-control'
                                                    required
                                                />
                                            </div>
                                    </Form.Group>

                                    <Form.Group as={Col} xs="12" sm="12" md="6" lg="6" xl="6" xxl="6" controlId="formGridEmail" className='mt-2'>
                                            <Form.Label>Service Name</Form.Label>
                                            <Form.Control type="text" placeholder="Service Name.." onChange={handleChange}
                                            value={formData.service_name} name="service_name" required/>
                                    </Form.Group>

                                    <Form.Group as={Col} xs="12" sm="12" md="6" lg="6" xl="6" xxl="6" controlId="formGridEmail" className='mt-2'>
                                            <Form.Label>From City/Area</Form.Label>
                                            <Form.Control type="text" onChange={handleChange} placeholder="From.." name="from_city" value={formData.from_city}  required/>
                                    </Form.Group>

                                    <Form.Group as={Col} xs="12" sm="12" md="6" lg="6" xl="6" xxl="6" controlId="formGridEmail" className='mt-2'>
                                        <Form.Label>To City/Area</Form.Label>
                                        <Form.Control type="text" onChange={handleChange} placeholder="To.." value={formData.to_city} name="to_city" required/>
                                    </Form.Group>

                                    <h6 className='mb-0 mt-3'>Shifting Charges :</h6>
                                    <Col xs="12" sm="12" md="12" lg="12" xl="12" xxl="12">
                                        <hr className='w-10'/>
                                    </Col>

                                    <Form.Group as={Col} xs="12" sm="12" md="6" lg="6" xl="6" xxl="6" controlId="formGridPassword" className='mt-2'>
                                        <Form.Label>Shifting Start Date</Form.Label>
                                            <div className="date-picker-container">
                                                <i className='calendar-icon fa fa-calendar'></i>
                                                <DatePicker
                                                    selected={formData.shift_start_date}
                                                    onChange={handleStartDateChange}
                                                    dateFormat="dd-MM-yyyy"
                                                    placeholderText="Start Date"
                                                    className='form-control'
                                                    required
                                                />
                                            </div>
                                    </Form.Group>

                                    <Form.Group as={Col} xs="12" sm="12" md="6" lg="6" xl="6" xxl="6" controlId="formGridPassword" className='mt-2'>
                                        <Form.Label>Shifting End Date</Form.Label>
                                            <div className="date-picker-container">
                                                <i className='calendar-icon fa fa-calendar'></i>
                                                <DatePicker
                                                    selected={formData.shift_end_date}
                                                    onChange={handleEndDateChange}
                                                    dateFormat="dd-MM-yyyy"
                                                    placeholderText="End Date"
                                                    className='form-control'
                                                    required
                                                />
                                            </div>
                                            {error_edit?.shift_end_date && (
                                            <span className="alert alert-danger d-block mt-1 mb-1 pt-1 pb-1">
                                            {error_edit.shift_end_date && error_edit.shift_end_date}
                                            </span>
                                            )}
                                    </Form.Group>

                                    <Form.Group as={Col} xs="12" sm="12" md="12" lg="12" xl="12" xxl="12" controlId="formGridPassword" className='mt-2'>
                                        <Form.Label>Payment Type</Form.Label>
                                        <Form.Select aria-label="Default select example" onChange={handleChange} name="payment_type" value={formData.payment_type}>
                                            <option value="cash">Cash</option>
                                            <option value="online">Online</option>
                                            <option value="Cheque">Cheque</option>
                                        </Form.Select>
                                    </Form.Group>

                                    <Form.Group as={Col} xs="12" sm="12" md="6" lg="6" xl="6" xxl="6" controlId="formGridPassword" className='mt-2'>
                                        <Form.Label>Total Amount</Form.Label>
                                        <Form.Control type="number" min={0} placeholder="Total Amount.." onChange={handleChange} value={formData.total_amount} name="total_amount" required/>
                                    </Form.Group>

                                    <Form.Group as={Col} xs="12" sm="12" md="6" lg="6" xl="6" xxl="6" controlId="formGridPassword" className='mt-2'>
                                        <Form.Label>Received Amount</Form.Label>
                                        <Form.Control type="number" min={0} onChange={handleChange} placeholder="Received Amount" name="received_amount" value={formData.received_amount} required/>
                                        {error_edit?.received_amount && (
                                        <span className="alert alert-danger d-block mt-1 pt-1 pb-1">
                                        {error_edit.received_amount && error_edit.received_amount}
                                        </span>
                                        )}
                                    </Form.Group>
                                </Row>

                                <Button variant="primary" type="submit" className='d-flex align-items-center justify-content-center'>
                                    <span className='mx-2'>Update</span>
                                    {loading_update && <span className='spin-sm'><Loader /></span>}
                                </Button>
                            </Form>
                    </div>
                </div>
            </div>
            {/* Edit Receipts Sidebar End */}
        </div>
    </>
  )
}

export default ReceiptsList
